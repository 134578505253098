
.invoicesBox {
  padding: 50px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: black;
}

.content {
  text-align: start;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th {
  text-align: left;
  font-size: 17px;
}
.wireTable th{
  width: 50%; 
}
.footerText {
  padding: 5px;
  font-size: 15px;
  color: grey;
  font-style: italic;
}
.tableContainer{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 60px;
}
input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #344767;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
  }
  
 
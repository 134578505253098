
.container {
  /* width: 100%;
  margin: 2rem; */
  padding: 0;
  border: 2px solid #dddddd;
  border-radius: 10px;
}
.container:hover{
  cursor: pointer;
  border: 2px solid #bbbbbb;
}

.content {
  text-align: right;
  /* margin: 1.5rem; */
  padding:0.8rem;
  /* font-family: "Poppins"; */
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
 
}

.FooterContent {
  text-align: left;
  margin-left: 1rem;
  color: #111;
  font-weight: 800;
  margin-bottom: 1rem;
}

.smallCard-icon{
  color:#ffffff !important;
  background-color:#dddddd !important;
  border-radius:10px !important;
}
.smallCard-icon:hover{
  color:white !important;
  background-color: #044484  !important;
}
.css-9q3qz0-MuiPaper-root{
  box-shadow: none !important;
}

.invoiceBox {
    max-width:"fit-content";
margin-bottom :10px;
padding:30px;
border:1px solid #eee;
box-shadow:0 0 10px rgba(0, 0, 0, .15);
font-size:16px;
line-height:24px;
font-family:'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
color:#555;

}

.invoiceHeader{
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.headerContent{
    text-align-last: end;
}

.logoStyle{
    width: 25%;
    height: 10%;
    align-self: end;
}
.invoiceFooter {
    margin-top: 20px;
}
/* .invoiceList{
    padding: 20px;
} */

.invoiceList {
  counter-reset: item;
}

.numberedItem {
  counter-increment: item;
  /* margin-bottom: 10px; */
  position: relative;
}

.numberedItem:before {
  content: counter(item) ". ";
  /* font-weight: bold; */
  position: absolute;
  left: -17px;
}

.pStyle{
    font-style: italic;
    /* page-break-before: always; */
}
table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
  }
  th {
    text-align: left;
  }

  .logos {
    display: flex;
    justify-content: space-between;
    page-break-before: always;
  }
  .logosStyle{
    width: 15%;
    height: 10%;
    align-self: end;
    margin: 10px;
}
.footerStyle{
    font-style: italic;
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    color: rgb(175, 171, 171);
    padding: 5px;
}

.footerText {
    padding: 5px;
    font-size: 10px;
    color: grey;
    font-style: italic;
  }
  .textAlignmentCenter{
    text-align-last: center;
  }
  .textAlignmentRight{
    text-align-last: end;
  }
  .textFit{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

  }
  .notePoint{
    font-weight: bold;
  }
  .hr-line {
    width: 100%;
    margin:  0 auto; /* Adjust as needed to control the spacing around the line */
    /* border-top: 1px solid #000; */
  }
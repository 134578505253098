.MuiFormHelperText-root {
  color: red !important;
  margin-left: 0 !important;
}
a {
  color: grey;
}
a:hover {
  color: black;
}
::-webkit-scrollbar {
  width: 0.4rem;
  background-color: "linear-gradient(104deg,#000000,#044484 102%)";
}
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 0.2rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.marginOnTop{
  margin-top: 3rem;
 
}
.dashboardLayout{
  background-color: red;
}
.input-labels{
  display:flex !important;
  align-items:center;
  width:100%;
  height:100%;
}
.top-navbar{
  position: relative !important;
  top: "0px";
  width: "100%";
  background-color: "white";
  border-radius: "0px";
}
 @media screen and (max-width:768px){
.right-logout-div{
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
}
@media screen and (min-width:1200px)
{
  .dashboard-layout{
    margin-left: 15.6rem !important;
  }
}


